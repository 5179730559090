import React, { useEffect, useState } from "react"
import axios from "axios"
import { Link } from "gatsby"
import Modal from "react-modal"
import Button from "src/components/Button/Button"
import "./DownloadForm.scss"

const serverUrl = process.env.GATSBY_SERVER_URL

const MODAL_NAMES = {
  OS: "OS",
  MOBILE: "MOBILE",
  NONE: "NONE",
}

const DownloadForm = ({ t, text }) => {
  const [os, setOs] = useState()
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false)
  const [visibleModal, setVisibleModal] = useState("")

  const [osSubscriberEmail, setOsSubscriberEmail] = useState("")
  const [mobileEmail, setMobileEmail] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const isWindows = os === "Windows"
  const isLinux = os === "Linux"

  useEffect(() => {
    import("src/utils/helpers").then(
      ({ getOS, isMobileOrTablet: isMobileOrTabletFn }) => {
        setOs(getOS())
        setIsMobileOrTablet(isMobileOrTabletFn())
      }
    )

  }, [])

  const showSubscribeModal = async () => {
    setVisibleModal(MODAL_NAMES.OS)
    reset()
  }

  const showSendMeEmailModal = async () => {
    setVisibleModal(MODAL_NAMES.MOBILE)
    reset()
  }

  const submitOsSubscriber = async () => {
    if (isSent) {
      setVisibleModal(MODAL_NAMES.NONE)
      return
    }
    if (!isValidEmail(osSubscriberEmail)) return

    setIsSending(isSending)
    await axios.post(`${serverUrl}/subscribe/os`, {
      email: osSubscriberEmail,
      os: os,
      type: os.toUpperCase(),
    })

    setIsSending(false)
    setIsSent(true)
  }

  const submitMobileSubscriber = async () => {
    if (isSent) {
      setVisibleModal(MODAL_NAMES.NONE)
      return
    }
    if (!isValidEmail(mobileEmail)) return

    setIsSending(isSending)
    await axios.post(`${serverUrl}/subscribe/mobile`, {
      email: mobileEmail,
      os: os,
      type: "MOBILE",
    })

    setIsSending(false)
    setIsSent(true)
  }

  const closeModal = async () => {
    setVisibleModal(MODAL_NAMES.NONE)
  }

  const isValidEmail = email => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const reset = async () => {
    setOsSubscriberEmail("")
    setMobileEmail("")
    setIsSending(false)
    setIsSent(false)
  }

  const onFieldChange = setter => e => {
    setter(e.target.value)
  }

  const onkeyPress = submit => e => {
    if (e.keyCode === 13) {
      submit(e)
    }
  }

  let downloadIcon = require("src/assets/companies/apple.svg")

  if (isWindows) {
    downloadIcon = require("src/assets/companies/windows-8.svg")
  }

  if (isLinux) {
    downloadIcon = require("src/assets/companies/linux.svg")
  }

  return (
    <div className="download-form mt-4 mb-2">
      {/* {!isMobileOrTablet && !isLinux &&  (
        <div>
          <Button href="/download" className="mx-1 mb-2" >
            <img
              src={downloadIcon}
              className="download-button-icon mr-2"
              alt=""
            />
            {text ? text : t("common.download")}
          </Button>
        </div>
      )}

      {isLinux && (
        <Button className="mx-1 mb-2" onClick={showSubscribeModal}>
          <img
            src={require("src/assets/companies/linux.svg")}
            className="download-button-icon mr-2"
            alt=""
          />
          Linux
        </Button>
      )}

      {isMobileOrTablet && (
        <Button className="mb-2" onClick={showSendMeEmailModal}>
          <img
            src={require("src/assets/icons/email.svg")}
            className="download-button-icon mr-2"
            alt=""
          />
          Email me the link
        </Button>
      )} */}

      <Button className="mb-2" href="https://form.typeform.com/to/R0gh69BK">
        <b>Join the Waitlist</b>
      </Button>

      <Modal
        isOpen={visibleModal === MODAL_NAMES.OS}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName={"ReactModal__Overlay"}
        className={"ReactModal__Content"}
      >
        <div className="s-modal">
          <div className="s-modal__header">Thanks for checking in!</div>
          <div className="s-modal__body">
            After months of design and development, we are finally releasing
            Stack for Linux in just a few days! We'll let you know as soon as
            it's live.
            <div className="form-group mt-4">
              <label className="font-weight-bold" htmlFor="exampleInputEmail1">
                Where shall we text you?
              </label>
              <input
                onChange={onFieldChange(setOsSubscriberEmail)}
                onKeyDown={onkeyPress(submitOsSubscriber)}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Enter email"
              />
            </div>
          </div>
          {isSent && (
            <div className="alert alert-success" role="alert">
              We received your email. Thank you!
            </div>
          )}
          <div className="s-modal__footer">
            <div>
              <button
                onClick={() => setVisibleModal(MODAL_NAMES.NONE)}
                className="btn btn-link"
              >
                No thanks
              </button>
            </div>
            <div>
              {!isSent && (
                <button
                  onClick={submitOsSubscriber}
                  disabled={isSending || !isValidEmail(osSubscriberEmail)}
                  className="btn btn-primary ml-2"
                >
                  {isSending ? "Sending..." : "Remind me"}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        isOpen={visibleModal === MODAL_NAMES.MOBILE}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName={"ReactModal__Overlay"}
        className={"ReactModal__Content"}
      >
        <div className="s-modal">
          <div className="s-modal__header">
            Hey there! Let us send you a link to download Stack.
          </div>
          <div className="s-modal__body">
            Stack currently runs on Mac and Windows, if you are on Linux, please
            write to customer support so we send you the beta testing Link.
            <div className="form-group mt-4">
              <label className="font-weight-bold" htmlFor="exampleInputEmail1">
                Enter your email
              </label>
              <input
                onChange={onFieldChange(setMobileEmail)}
                onKeyDown={onkeyPress(submitMobileSubscriber)}
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                placeholder="Your email"
              />
            </div>
          </div>
          {isSent && (
            <div className="alert alert-success" role="alert">
              We've sent you a download link to your email.
            </div>
          )}
          <div className="s-modal__footer">
            <div>
              <button
                onClick={() => setVisibleModal(MODAL_NAMES.NONE)}
                className="btn btn-secondary"
              >
                Close
              </button>
            </div>
            <div>
              {!isSent && (
                <button
                  onClick={submitMobileSubscriber}
                  disabled={isSending || !isValidEmail(mobileEmail)}
                  className="btn btn-primary ml-2"
                >
                  {isSending ? "Sending..." : "Submit"}
                </button>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default DownloadForm
