import React from "react"
import { Link } from "gatsby"

import LangSwitch from "src/components/LangSwitch/LangSwitch"

import { IoLogoFacebook, IoLogoTwitter, IoLogoLinkedin, IoLogoReddit } from "react-icons/io"
import "./Footer.scss"

const Footer = ({ theme = 'dark', lang }) => (
  <div className={`footer footer--${theme}`}>
    <div className="footer-links">
      {/* <Link to="/pricing" className="mx-2">
        Pricing
      </Link> */}
      {/* <Link to="/refund-policy" className="mx-2">
        Refund policy
      </Link> */}
      <a href="/privacy-policy" className="mx-2">
        Privacy policy
      </a>
      <a href="/terms-and-conditions" className="mx-2">
        Terms & Conditions
      </a>
      <a href="https://help.stackbrowser.com" target="_blank" rel="noopener noreferrer" className="mx-2 footer-link--external">
        Help Center
      </a>
    </div>
    <div className="footer-socials">
      <a
        href="https://www.facebook.com/getstackbrowser/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <IoLogoFacebook className="footer-socials__icon" />
      </a>
      <a
        href="https://twitter.com/Stack_HQ"
        rel="noopener noreferrer"
        target="_blank"
      >
        <IoLogoTwitter className="footer-socials__icon" />
      </a>
      <a
        href="https://www.linkedin.com/company/appstack/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <IoLogoLinkedin className="footer-socials__icon" />
      </a>
      <a
        href="https://www.reddit.com/r/GetStack"
        rel="noopener noreferrer"
        target="_blank"
      >
        <IoLogoReddit className="footer-socials__icon" />
      </a>
    </div>
    <div className="mt-3">
      <LangSwitch lang={lang} />
    </div>
  </div>
)

export default Footer
