import React, { useEffect, useState } from 'react'
import './LangSwitch.scss'
import { Link } from "gatsby"

import usIcon from 'src/assets/icons/flags/us.png'
import geoIcon from 'src/assets/icons/flags/geo.png'

const LangSwitch = ({lang, layout}) => {
  const [isKa, setIsKa] = useState(false)

  useEffect(() => {
    if (window) {
      setIsKa(window.location.pathname.startsWith('/ka'))
    }
  }, [])

  return (
    <div className="lang-switch">
      <Link to="/">
        <button className={`lang-switch__item ${!isKa && 'lang-switch__item--active'}`}>
          <img src={usIcon} alt=""/>
        </button>
      </Link>
      <Link to="/ka">
        <button className={`lang-switch__item ${isKa && 'lang-switch__item--active'}`}>
          <img src={geoIcon} alt=""/>
        </button>
      </Link>
    </div>
  )
}

export default LangSwitch
