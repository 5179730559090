import React from "react"
import "./Button.scss"

const Button = ({ color, block, href, children, onClick = () => {}, className, progressAnimation, disabled }) => (
  <div
    className={`
    stack-button-wrapper stack-button-wrapper--${color}
    ${block ? "stack-button-wrapper--block" : ""}
    ${progressAnimation ? "stack-button-wrapper--progress" : ""}
    ${className}
  `}
  >
    <a href={href} className="stack-button" onClick={onClick}>
      {progressAnimation && <div className="stack-button-wrapper__progress"></div>}
      {children}
    </a>
  </div>
)

export default Button
